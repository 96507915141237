import React, { useEffect, useState, useRef } from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, PerspectiveCamera } from '@react-three/drei';
import Robot from './components/Robot';
import CustomAxesHelper from './components/CustomAxesHelper';
import kr10Config from './robots/kuka/kr10r1440-2/config';
import DialControl from './ui/DialControl/DialControl'; // Adjust the import path

const App = () => {
  const [config, setConfig] = useState(null);
  const orbitControlsRef = useRef();

  useEffect(() => {
    setConfig(kr10Config);
    console.log('Initial Config:', kr10Config);
  }, []);

  if (!config) return <div>Loading...</div>;

  return (
    <div style={{ width: '100vw', height: '100vh' }}>
      <Canvas shadows>
        <PerspectiveCamera makeDefault position={[3000, 3000, 3000]} fov={60} far={10000} />
        <ambientLight intensity={0.5} />
        <pointLight position={[10, 10, 10]} />
        <Robot
          initialJointAngles={config.initialJointAngles}
          controlConfig={config.controlConfig || {}}
          glbFiles={config.glbFiles}
          basePath={`${process.env.PUBLIC_URL}/robots/kuka/kr10r1440-2/`}
          dhParams={config.dhParams}
          showPlanes={true} // Add this prop to enable plane visualization
          orbitControlsRef={orbitControlsRef}
        />
        <OrbitControls ref={orbitControlsRef} target={[0, 500, 0]} />
        
        <fog attach="fog" args={['#f0f0f0', 3000, 8000]} />
      </Canvas>
    </div>
  );
};

export default App;