/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.0 C:\Users\karls\OneDrive\Prime\Robotics\Hype_Dynamics\_tskrl\react\hype_tester\public\robots\kuka\kr10r1440-2\a6.glb 
*/

import React from 'react'
import { useGLTF } from '@react-three/drei'

export function Model({ material, children, ...props }) {
  const { nodes } = useGLTF('/robots/kuka/kr10r1440-2/a6.glb')

  if (!nodes || !nodes.a6) {
    console.error('Node a6 not found in the GLTF file')
    return null
  }

  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.a6.geometry} material={material} position={[0, 0, 100]} rotation={[Math.PI, 0, Math.PI]}>
        {children}
      </mesh>
    </group>
  )
}

useGLTF.preload('/robots/kuka/kr10r1440-2/a6.glb')
