import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useControls, button } from 'leva';
import { forwardKinematics } from '../utils/kinematics';
import JointPlaneVisualizer from '../utils/JointPlaneVisualizer';
import RobotGeometry from './RobotGeometry'; 
import config from '../robots/kuka/kr10r1440-2/config';

const Robot = ({ orbitControlsRef, showPlanes }) => {
  const { initialJointAngles, controlConfig, dhParams, glbFiles } = config;
  const basePath = '/robots/kuka/kr10r1440-2/';

  const [showModels, setShowModels] = useState(true);

  // Use Leva to create controls
  const [controls, setControls] = useControls(() => ({
    ...controlConfig,
    'Toggle Models': button(() => {
      setShowModels(prev => !prev);
    }),
  }));

  // Convert controls object to array of joint angles
  const jointAngles = useMemo(() => {
    return Object.values(controls).filter(val => typeof val === 'number');
  }, [controls]);

  // Memoize the forward kinematics to avoid redundant recalculations
  const { positions, orientations, planes } = useMemo(() => {
    if (!jointAngles || !dhParams || jointAngles.length !== dhParams.length) {
      return { positions: [], orientations: [], planes: [] };
    }
    return forwardKinematics(jointAngles, dhParams);
  }, [jointAngles, dhParams]);

  const handleJointChange = useCallback((jointIndex, newAngle) => {
    const jointName = `A${jointIndex + 1}`;
    if (controls[jointName] !== undefined) {
      setControls({ [jointName]: newAngle });
    }
  }, [controls, setControls]);
  
  return (
    <group rotation={[-Math.PI / 2, 0, 0]}>
      {/* {showPlanes && planes.length > 0 && (
        <JointPlaneVisualizer planes={planes} />
      )} */}

      <RobotGeometry 
        positions={positions}
        orientations={orientations}
        planes={planes}
        showPlanes={false}
        showModels={showModels}
        orbitControlsRef={orbitControlsRef}
        onJointChange={handleJointChange}
        jointAngles={jointAngles}
      />
    </group>
  );
};

export default Robot;