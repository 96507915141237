import React from 'react';
import { useGLTF } from '@react-three/drei';

export function Model({ frameMaterial, motorMaterial, ...props }) {
  const { nodes } = useGLTF('/robots/kuka/kr10r1440-2/a1.glb');

  if (!nodes || !nodes.A1frame || !nodes.A1motor) {
    console.error('Node A1frame or A1motor not found in the GLTF file');
    return null;
  }

  return (
    <group {...props} dispose={null}>
      {/* Apply frameMaterial to the A1frame mesh */}
      <mesh geometry={nodes.A1frame.geometry} material={frameMaterial} />
      {/* Apply motorMaterial to the A1motor mesh */}
      <mesh geometry={nodes.A1motor.geometry} material={motorMaterial} />
    </group>
  );
}

useGLTF.preload('/robots/kuka/kr10r1440-2/a1.glb');
