/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.0 C:\Users\karls\OneDrive\Prime\Robotics\Hype_Dynamics\_tskrl\react\hype_tester\public\robots\kuka\kr10r1440-2\a3.glb 
*/

import React from 'react';
import { useGLTF } from '@react-three/drei';

export function Model({ frameMaterial, motorMaterial, ...props }) {
  const { nodes } = useGLTF('/robots/kuka/kr10r1440-2/a3.glb')

  if (!nodes || !nodes.A3frame || !nodes.A3motor) {
    console.error('Node A3frame or A3motor not found in the GLTF file')
    return null
  }

  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.A3frame.geometry} material={frameMaterial} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
      <mesh geometry={nodes.A3motor.geometry} material={motorMaterial} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
    </group>
  )
}

useGLTF.preload('/robots/kuka/kr10r1440-2/a3.glb')
