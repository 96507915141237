import * as THREE from 'three';

// Convert degrees to radians
export const degToRad = (deg) => deg * Math.PI / 180;

// Convert radians to degrees
export const radToDeg = (rad) => rad * 180 / Math.PI;

// DH Matrix function
export const dhMatrix = (a, alpha, d, theta) => {
  const ct = Math.cos(theta);
  const st = Math.sin(theta);
  const ca = Math.cos(alpha);
  const sa = Math.sin(alpha);

  return new THREE.Matrix4().set(
    ct, -st * ca, st * sa, a * ct,
    st, ct * ca, -ct * sa, a * st,
    0, sa, ca, d,
    0, 0, 0, 1
  );
};

// Setting up joint angles based on the example given
export const setupJointAngles = (a1, a2, a3, a4, a5, a6) => {
  // Convert angles to radians and apply the transformations as per the provided script
  return [
    degToRad(-a1),
    degToRad(-a2),
    degToRad(90 - a3),
    degToRad(-a4),
    degToRad(180 + a5),
    degToRad(-a6)
  ];
};
