import React, { useEffect, useRef, useMemo } from 'react';
import * as THREE from 'three';
import { useThree } from '@react-three/fiber';
import { Model as ModelA0 } from '../robots/kuka/kr10r1440-2/A0';
import { Model as ModelA1 } from '../robots/kuka/kr10r1440-2/A1';
import { Model as ModelA2 } from '../robots/kuka/kr10r1440-2/A2';
import { Model as ModelA3 } from '../robots/kuka/kr10r1440-2/A3';
import { Model as ModelA4 } from '../robots/kuka/kr10r1440-2/A4';
import { Model as ModelA5 } from '../robots/kuka/kr10r1440-2/A5';
import { Model as ModelA6 } from '../robots/kuka/kr10r1440-2/A6';
import config from '../robots/kuka/kr10r1440-2/config';
import DialControl from '../ui/DialControl/DialControl';
import { CustomPlane } from '../utils/customplane';
import JointPlaneVisualizer from '../utils/JointPlaneVisualizer';

const RobotGeometry = ({ orbitControlsRef, positions, orientations, planes, onJointChange, showModels, jointAngles, showPlanes = false }) => {
  const { scene } = useThree();
  const groupRefs = [useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef()];

  // **** DIAL CONTROL CONFIGURATION ****
  // Define the fixed offset of A1 relative to A0
  const A1_OFFSET = new THREE.Vector3(0, 0, 0); // Adjust these values as needed
  const DIAL_OFFSETS = {
    A1: 0,
    A2: 250,
    A3: 100,
    A4: 357,
    A5: -100,
    A6: 115
  };
  // Add this scale configuration object
  const DIAL_SCALES = {
    A1: 8,
    A2: 4,
    A3: 3,
    A4: 3,
    A5: 2,
    A6: 2
  };

  // Define materials
  const baseMaterial = useMemo(() => new THREE.MeshStandardMaterial({ color: 0x000000, metalness: 0.1, roughness: 0.3 }), []);
  const jointMaterial = useMemo(() => new THREE.MeshStandardMaterial({ color: 0xffffff, metalness: 0.1, roughness: 0.2 }), []);
  const endEffectorMaterial = useMemo(() => new THREE.MeshStandardMaterial({ color: 0x000000, metalness: 0.6, roughness: 0.3 }), []);

  // Create dynamic materials for joints
  const jointMaterials = useMemo(() => 
    Array(7).fill().map(() => new THREE.MeshStandardMaterial({ color: 0xffffff, metalness: 0.1, roughness: 0.2 })),
  []);

  useEffect(() => {
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
    scene.add(ambientLight);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 0.8);
    directionalLight.position.set(5, 5, 5);
    directionalLight.castShadow = true;
    scene.add(directionalLight);

    return () => {
      scene.remove(ambientLight);
      scene.remove(directionalLight);
    };
  }, [scene]);

  useEffect(() => {
    if (positions.length === 0 || orientations.length === 0 || planes.length === 0) return;

    groupRefs.forEach((ref, index) => {
      if (ref.current) {
        if (index === 0) {
          // A0 (base)
          ref.current.position.copy(positions[0]);
          ref.current.setRotationFromEuler(orientations[0]);
        } else if (index === 1) {
          // A1 (first joint)
          ref.current.position.copy(A1_OFFSET);
          ref.current.setRotationFromEuler(orientations[1]);
        } else {
          // A2 to A6
          ref.current.position.copy(positions[index - 1]);
          ref.current.setRotationFromEuler(orientations[index]);
        }
        
        if (index > 0) {
          updateJointMaterial(jointMaterials[index], jointAngles[index - 1], config.controlConfig[`A${index}`]);
        }
      }
    });
  }, [positions, orientations, planes, jointMaterials, jointAngles, A1_OFFSET]);

  const updateJointMaterial = (material, angle, jointConfig) => {
    const { min, max } = jointConfig;
    const range = max - min;
    const threshold = range * 0.1; // 10% of the range

    let t = 0;
    if (angle > max - threshold) {
      t = (angle - (max - threshold)) / threshold;
    } else if (angle < min + threshold) {
      t = ((min + threshold) - angle) / threshold;
    }

    t = Math.max(0, Math.min(1, t)); // Clamp t between 0 and 1

    const baseColor = new THREE.Color(0xffffff); // White
    const limitColor = new THREE.Color(0xff0000); // Red
    material.color.copy(baseColor.lerp(limitColor, t));
  };

  const renderDialControl = (jointIndex, jointName) => {
    if (planes[jointIndex] && jointAngles && jointAngles.length > jointIndex) {
      const plane = planes[jointIndex];
      
      // Use the plane's origin as the base position
      let position = plane.origin.clone();
      
      // Offsets for each dial
      const offset = DIAL_OFFSETS[jointName] || 0; // Default to 0 if not specified
      const offsetVector = plane.zAxisNorm.clone().multiplyScalar(offset);
      position.add(offsetVector);
      
      // Calculate rotation from the plane's axes
      const rotationMatrix = new THREE.Matrix4().makeBasis(
        plane.xAxisNorm,
        plane.yAxisNorm,
        plane.zAxisNorm
      );
      const rotation = new THREE.Euler().setFromRotationMatrix(rotationMatrix);

      // Special case for A5: Flip the dial control
      if (jointName === 'A5') {
        // Create a rotation that's 180 degrees around the X-axis
        const flipRotation = new THREE.Euler(Math.PI, 0, 0);
        // Apply this rotation to the existing rotation
        const flippedMatrix = new THREE.Matrix4().makeRotationFromEuler(rotation).multiply(new THREE.Matrix4().makeRotationFromEuler(flipRotation));
        rotation.setFromRotationMatrix(flippedMatrix);
      }

      // Get the scale for this specific dial
      const scale = DIAL_SCALES[jointName] || 1; // Default to 1 if not specified

      return (
        <DialControl
          key={`${jointName}Dial`}
          minDegrees={config.controlConfig[jointName].min}
          maxDegrees={config.controlConfig[jointName].max}
          defaultPosition={jointAngles[jointIndex]}
          onChange={(angle) => onJointChange(jointIndex, angle)}
          position={position.toArray()}
          rotation={rotation.toArray()}
          scale={scale}
          jointName={jointName}
          orbitControlsRef={orbitControlsRef}
          showPlanes={false}
          currentAngle={jointAngles[jointIndex]}
        />
      );
    }
    return null;
  };

  return (
    <group position={[0, 0, 0]}>
      {showModels && (
        <>
          <group ref={groupRefs[0]}><ModelA0 material={baseMaterial} /></group>
          <group ref={groupRefs[1]}><ModelA1 frameMaterial={jointMaterials[1]} motorMaterial={baseMaterial} /></group>
          <group ref={groupRefs[2]}><ModelA2 material={jointMaterials[2]} /></group>
          <group ref={groupRefs[3]}><ModelA3 frameMaterial={jointMaterials[3]} motorMaterial={baseMaterial} /></group>
          <group ref={groupRefs[4]}><ModelA4 material={jointMaterials[4]} /></group>
          <group ref={groupRefs[5]}><ModelA5 material={jointMaterials[5]} /></group>
          <group ref={groupRefs[6]}><ModelA6 material={endEffectorMaterial} /></group>
        </>
      )}
      {/* Conditionally render JointPlaneVisualizer based on showPlanes */}
      {showPlanes && planes.length > 0 && (
        <JointPlaneVisualizer planes={planes} />
      )}
      {planes.map((_, index) => renderDialControl(index, `A${index + 1}`))}
    </group>
  );
};

export default RobotGeometry;