import React from 'react';
import { PlaneVisualizer } from './customplane';

const JointPlaneVisualizer = ({ planes }) => {
  return (
    <group>
      {planes.map((plane, index) => (
        <PlaneVisualizer key={index} plane={plane} />
      ))}
    </group>
  );
};

export default JointPlaneVisualizer;
