import React, { useRef, useState, useEffect, useMemo, useCallback } from 'react';
import { useThree, useFrame } from '@react-three/fiber';
import { Line, Text } from '@react-three/drei';
import * as THREE from 'three';

function DialControl({ 
  minDegrees, 
  maxDegrees, 
  defaultPosition, 
  onChange,
  position = [0, 0, 0],
  rotation = [0, 0, 0],
  scale = 1,
  currentAngle,
  orbitControlsRef,
  isVertical = false  // New prop to indicate if the dial is vertical
}) {

  const innerRadius = 35 * scale;  // Inner circle scales with the input
  const outerRadius = innerRadius + 40; // Outer circle remains a fixed distance from the inner circle
  const minRadians = THREE.MathUtils.degToRad(-maxDegrees);
  const maxRadians = THREE.MathUtils.degToRad(-minDegrees);
  
  const normalActiveAreaRadius = outerRadius + 10 * scale;
  const expandedActiveAreaRadius = outerRadius + 50 * scale;

  const textRadius = outerRadius + 30;

  const [dragging, setDragging] = useState(false);
  const [angle, setAngle] = useState(THREE.MathUtils.degToRad(-defaultPosition));
  const [activeAreaRadius, setActiveAreaRadius] = useState(normalActiveAreaRadius);
  const [opacity, setOpacity] = useState(0.5);
  const [isHovered, setIsHovered] = useState(false);

  const dialRef = useRef();
  const handleRef = useRef();
  const backgroundRef = useRef();
  const textRef = useRef();
  const textGroupRef = useRef();
  const activeAreaRef = useRef();

  const { camera } = useThree();

  const lineColor = "#565656";
  const normalBackgroundColor = new THREE.Color("#4E95D9");
  const warningBackgroundColor = new THREE.Color("#D8507D");

  const lineZOffset = 0.8; // Small offset to move lines above other elements

  const isFullCircle = Math.abs(maxDegrees - minDegrees) >= 350; // Consider it a full circle if range is 350 degrees or more


  
  const generateArc = useCallback((radius, startAngle, endAngle, numPoints = 100) => {
    const points = [];
    for (let i = 0; i <= numPoints; i++) {
      const angle = startAngle + (endAngle - startAngle) * (i / numPoints);
      points.push(new THREE.Vector3(Math.cos(angle) * radius, Math.sin(angle) * radius, 0));
    }
    return points;
  }, []);

  const createActiveAreaGeometry = useCallback((radius, isExpanded) => {
    const buffer = THREE.MathUtils.degToRad(5); // Smaller buffer for tighter fit
    let outerArc, innerArc;

    if (isExpanded || isFullCircle) {
      // Full circle for expanded area or when range is large
      outerArc = generateArc(radius, 0, Math.PI * 2);
      innerArc = generateArc(0, Math.PI * 2, 0); // Single point at center
    }

    if (isExpanded) {
      // Full circle for expanded area
      outerArc = generateArc(radius, 0, Math.PI * 2);
      innerArc = generateArc(0, Math.PI * 2, 0); // Single point at center
    } else {
      // Semi-circle for normal area
      outerArc = generateArc(radius, minRadians - buffer, maxRadians + buffer);
      innerArc = generateArc(innerRadius * 0.9, maxRadians + buffer, minRadians - buffer).reverse();
    }

    const points = [...outerArc, ...innerArc];

    
    
    const geometry = new THREE.BufferGeometry();
    geometry.setAttribute('position', new THREE.Float32BufferAttribute(points.flatMap(p => [p.x, p.y, p.z]), 3));
    
    // Create faces for the geometry
    const indices = [];
    for (let i = 0; i < outerArc.length - 1; i++) {
      indices.push(i, i + 1, outerArc.length + (isExpanded ? 0 : i));
      if (!isExpanded) {
        indices.push(i + 1, outerArc.length + i + 1, outerArc.length + i);
      }
    }
    geometry.setIndex(indices);
    
    return geometry;
  }, [generateArc, minRadians, maxRadians, innerRadius]);

  const activeAreaGeometry = useMemo(() => createActiveAreaGeometry(normalActiveAreaRadius, false), 
    [createActiveAreaGeometry, normalActiveAreaRadius]);

  const backgroundGeometry = useMemo(() => {
    const shape = new THREE.Shape();
    const outerArc = generateArc(outerRadius, isFullCircle ? 0 : minRadians, isFullCircle ? Math.PI * 2 : maxRadians);
    shape.moveTo(outerArc[0].x, outerArc[0].y);
    outerArc.forEach(point => shape.lineTo(point.x, point.y));
    if (!isFullCircle) {
      const innerArc = generateArc(innerRadius, maxRadians, minRadians);
      innerArc.forEach(point => shape.lineTo(point.x, point.y));
    } else {
      shape.absarc(0, 0, innerRadius, 0, Math.PI * 2, true);
    }
    shape.closePath();

    return new THREE.ShapeGeometry(shape);
  }, [generateArc, minRadians, maxRadians, innerRadius, outerRadius]);

  const dialShape = useMemo(() => generateArc(outerRadius, minRadians, maxRadians), [generateArc, outerRadius, minRadians, maxRadians]);
  const innerShape = useMemo(() => generateArc(innerRadius, minRadians, maxRadians), [generateArc, innerRadius, minRadians, maxRadians]);

  const handlePointerEnter = useCallback(() => {
    setIsHovered(true);
  }, []);

  const handlePointerLeave = useCallback(() => {
    setIsHovered(false);
  }, []);

  const handlePointerDown = useCallback((event) => {
    event.stopPropagation();
    setDragging(true);
    setActiveAreaRadius(expandedActiveAreaRadius);
    if (orbitControlsRef.current) orbitControlsRef.current.enabled = false;
  }, [expandedActiveAreaRadius, orbitControlsRef]);

  const handlePointerUp = useCallback((event) => {
    event.stopPropagation();
    setDragging(false);
    setActiveAreaRadius(normalActiveAreaRadius);
    if (orbitControlsRef.current) orbitControlsRef.current.enabled = true;
  }, [normalActiveAreaRadius, orbitControlsRef]);

  const handlePointerMove = useCallback((event) => {
    if (dragging) {
      event.stopPropagation();
      const { point } = event;
      const localPoint = new THREE.Vector3().copy(point).applyMatrix4(dialRef.current.matrixWorld.invert());
      const newAngle = Math.atan2(localPoint.y, localPoint.x);
      const clampedAngle = THREE.MathUtils.clamp(newAngle, minRadians, maxRadians);
      setAngle(clampedAngle);
      onChange(-THREE.MathUtils.radToDeg(clampedAngle));
    }
  }, [dragging, minRadians, maxRadians, onChange]);

  // Update opacity based on hover state
  useFrame(() => {
    const targetOpacity = isHovered ? 0.5 : 0.1;
    setOpacity(THREE.MathUtils.lerp(opacity, targetOpacity, 0.1));
  });

  useEffect(() => {
    if (!dialRef.current || !handleRef.current || !textGroupRef.current || !textRef.current) {
      return; // Exit if any of the refs are not available
    }

    const handleStartX = Math.cos(angle) * innerRadius;
    const handleStartY = Math.sin(angle) * innerRadius;
    const handleEndX = Math.cos(angle) * (outerRadius + 15);
    const handleEndY = Math.sin(angle) * (outerRadius + 15);

    handleRef.current.position.set(
      (handleStartX + handleEndX) / 2,
      (handleStartY + handleEndY) / 2,
      lineZOffset
    );
    handleRef.current.scale.set(
      Math.sqrt((handleEndX - handleStartX) ** 2 + (handleEndY - handleStartY) ** 2),
      5, // handle height
      1
    );
    handleRef.current.rotation.z = angle;

    

    // Position the text
    textGroupRef.current.position.set(
      Math.cos(angle) * textRadius,
      Math.sin(angle) * textRadius,
      0.1
    );

    // Rotate the text to always face outward
    textGroupRef.current.rotation.z = angle + Math.PI / 2;

    // Update text content
    textRef.current.text = `${-Math.round(THREE.MathUtils.radToDeg(angle))}°`;

    if (backgroundRef.current) {
      const range = maxRadians - minRadians;
      const threshold = range * 0.1;
      let t = 0;

      // Convert angle to degrees for easier comparison
      const angleDegrees = -THREE.MathUtils.radToDeg(angle);
      
      if (angleDegrees < minDegrees + 10) {
        t = (minDegrees + 10 - angleDegrees) / 10;
      } else if (angleDegrees > maxDegrees - 10) {
        t = (angleDegrees - (maxDegrees - 10)) / 10;
      }

      t = THREE.MathUtils.clamp(t, 0, 1);

      const newColor = normalBackgroundColor.clone().lerp(warningBackgroundColor, t);
      backgroundRef.current.material.color = newColor;
    }

    if (activeAreaRef.current) {
      const newGeometry = createActiveAreaGeometry(activeAreaRadius, dragging);
      activeAreaRef.current.geometry.dispose();
      activeAreaRef.current.geometry = newGeometry;
    }
    
  }, [angle, innerRadius, outerRadius, scale, activeAreaRadius, minRadians, maxRadians, createActiveAreaGeometry, dragging, minDegrees, maxDegrees]);


  useEffect(() => {
    setAngle(THREE.MathUtils.degToRad(-currentAngle));
  }, [currentAngle]);

  return (
    <group ref={dialRef} position={position} rotation={rotation}>
      <mesh
        ref={activeAreaRef}
        onPointerEnter={handlePointerEnter}
        onPointerLeave={handlePointerLeave}
        onPointerDown={handlePointerDown}
        onPointerUp={handlePointerUp}
        onPointerMove={handlePointerMove}
      >
        <bufferGeometry attach="geometry" {...activeAreaGeometry} />
        <meshBasicMaterial visible={false} />
      </mesh>

      <mesh ref={backgroundRef} geometry={backgroundGeometry}>
        <meshBasicMaterial transparent opacity={opacity} side={THREE.DoubleSide} />
      </mesh>

      <Line points={dialShape} color={lineColor} lineWidth={1} position={[0, 0, lineZOffset]} transparent opacity={opacity} />
      <Line points={innerShape} color={lineColor} lineWidth={1} position={[0, 0, lineZOffset]} transparent opacity={opacity} />
      <Line
        points={[
          new THREE.Vector3(Math.cos(maxRadians) * innerRadius, Math.sin(maxRadians) * innerRadius, 0),
          new THREE.Vector3(Math.cos(maxRadians) * outerRadius, Math.sin(maxRadians) * outerRadius, 0),
        ]}
        color={lineColor}
        lineWidth={1}
        position={[0, 0, 0.05]}
        transparent
        opacity={opacity}
      />
      <Line
        points={[
          new THREE.Vector3(Math.cos(minRadians) * innerRadius, Math.sin(minRadians) * innerRadius, 0),
          new THREE.Vector3(Math.cos(minRadians) * outerRadius, Math.sin(minRadians) * outerRadius, 0),
        ]}
        color={lineColor}
        lineWidth={1}
        position={[0, 0, 0.05]}
        transparent
        opacity={opacity}
      />
      
      <mesh ref={handleRef}>
        <planeGeometry args={[1, 1]} />
        <meshBasicMaterial color={lineColor} transparent opacity={opacity} />
      </mesh>

      <group ref={textGroupRef}>
        <Text
          ref={textRef}
          color={lineColor}
          fontSize={20}
          font="/fonts/Roboto-Medium.ttf"
          anchorX="center"
          anchorY="middle"
          transparent
          opacity={opacity}
        >
          {-Math.round(THREE.MathUtils.radToDeg(angle))}°
        </Text>
      </group>
    </group>
  );
}

export default DialControl;