// src/robots/kuka/kr10r1440-2/config.js
const config = {
  robotName: "KUKA KR 10 R1440-2",
  dhParams: [
    [170, Math.PI / 2, 425, 0], // Joint 1
    [610, 0, 0, 0],             // Joint 2
    [20, Math.PI / 2, 0, 0],    // Joint 3
    [0, Math.PI / 2, 660, 0],   // Joint 4
    [0, Math.PI / 2, 0, 0],     // Joint 5
    [0, 0, 100, 0]              // Joint 6
  ],
  initialJointAngles: [0, -90, 90, 0, 0, 0],
  controlConfig: {
    A1: { value: 0, min: -170, max: 170 },
    A2: { value: -90, min: -185, max: 65 },
    A3: { value: 90, min: -137, max: 163 },
    A4: { value: 0, min: -185, max: 185 },
    A5: { value: 0, min: -120, max: 120 },
    A6: { value: 0, min: -350, max: 350 }
  },
  glbFiles: ['A0.glb', 'A1.glb', 'A2.glb', 'A3.glb', 'A4.glb', 'A5.glb', 'A6.glb']
};

export default config;
