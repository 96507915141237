import * as THREE from 'three';
import { dhMatrix, setupJointAngles } from './transformations';
import { CustomPlane } from './customplane';

const AXIS_LENGTH = 80; // Length of the axes for visualization

let cachedJointAngles = null;
let cachedResult = null;

export const forwardKinematics = (jointAnglesInput, dhParams, robrootPlane = null) => {
  // Check if the joint angles have changed
  if (cachedJointAngles && JSON.stringify(jointAnglesInput) === JSON.stringify(cachedJointAngles)) {
    return cachedResult;
  }

  // Update cached joint angles
  cachedJointAngles = [...jointAnglesInput];

  // Convert joint angles using the setupJointAngles function
  const jointAngles = setupJointAngles(...jointAnglesInput);

  // Initialize the first plane (ROBROOT or WorldXY)
  let currentTransform;
  if (robrootPlane) {
    currentTransform = new THREE.Matrix4().set(
      robrootPlane.xAxis.x, robrootPlane.yAxis.x, robrootPlane.zAxis.x, robrootPlane.origin.x,
      robrootPlane.xAxis.y, robrootPlane.yAxis.y, robrootPlane.zAxis.y, robrootPlane.origin.y,
      robrootPlane.xAxis.z, robrootPlane.yAxis.z, robrootPlane.zAxis.z, robrootPlane.origin.z,
      0, 0, 0, 1
    );
  } else {
    currentTransform = new THREE.Matrix4().identity();
  }

  const positions = [new THREE.Vector3(currentTransform.elements[12], currentTransform.elements[13], currentTransform.elements[14])];
  const orientations = [new THREE.Euler().setFromRotationMatrix(currentTransform)];
  const planes = [new CustomPlane()];

  // Loop through each joint to calculate the transformation
  jointAngles.forEach((angle, i) => {
    const [a, alpha, d, _] = dhParams[i];
    const dhTransform = dhMatrix(a, alpha, d, angle);
    currentTransform.multiply(dhTransform);

    const position = new THREE.Vector3().setFromMatrixPosition(currentTransform);
    const orientation = new THREE.Euler().setFromRotationMatrix(currentTransform);

    positions.push(position);
    orientations.push(orientation);

    const xAxis = new THREE.Vector3(currentTransform.elements[0], currentTransform.elements[1], currentTransform.elements[2]).normalize().multiplyScalar(AXIS_LENGTH);
    const yAxis = new THREE.Vector3(currentTransform.elements[4], currentTransform.elements[5], currentTransform.elements[6]).normalize().multiplyScalar(AXIS_LENGTH);
    const zAxis = new THREE.Vector3(currentTransform.elements[8], currentTransform.elements[9], currentTransform.elements[10]).normalize().multiplyScalar(AXIS_LENGTH);

    planes.push(new CustomPlane(position, xAxis, yAxis, zAxis));
  });

  // Cache the result
  cachedResult = { positions, orientations, planes };

  return cachedResult;
};
