import * as THREE from 'three';
import React from 'react';
import { Line } from '@react-three/drei';

class CustomPlane {
  constructor(
    origin = new THREE.Vector3(0, 0, 0),
    xAxis = new THREE.Vector3(1, 0, 0).normalize().multiplyScalar(80),
    yAxis = new THREE.Vector3(0, 1, 0).normalize().multiplyScalar(80),
    zAxis = new THREE.Vector3(0, 0, 1).normalize().multiplyScalar(80)
  ) {
    this.origin = origin;
    this.xAxis = xAxis;
    this.yAxis = yAxis;
    this.zAxis = zAxis;
    
    // Store the original lengths
    this.xLength = xAxis.length();
    this.yLength = yAxis.length();
    this.zLength = zAxis.length();

    // Normalize the axes for internal calculations
    this.xAxisNorm = xAxis.clone().normalize();
    this.yAxisNorm = yAxis.clone().normalize();
    this.zAxisNorm = zAxis.clone().normalize();
  }

  static fromRotationX(angle) {
    const radians = THREE.MathUtils.degToRad(angle);
    const cos = Math.cos(radians);
    const sin = Math.sin(radians);
    
    return new CustomPlane(
      new THREE.Vector3(0, 0, 0),
      new THREE.Vector3(1, 0, 0).normalize().multiplyScalar(80),
      new THREE.Vector3(0, cos, sin).normalize().multiplyScalar(80),
      new THREE.Vector3(0, -sin, cos).normalize().multiplyScalar(80)
    );
  }

  toMatrix4() {
    const m = new THREE.Matrix4();
    m.makeBasis(this.xAxisNorm, this.yAxisNorm, this.zAxisNorm);
    m.setPosition(this.origin);
    return m;
  }

  applyMatrix4(matrix) {
    const origin = this.origin.applyMatrix4(matrix);
    const xAxis = this.xAxisNorm.clone().applyMatrix4(matrix).sub(origin).normalize().multiplyScalar(this.xLength);
    const yAxis = this.yAxisNorm.clone().applyMatrix4(matrix).sub(origin).normalize().multiplyScalar(this.yLength);
    const zAxis = this.zAxisNorm.clone().applyMatrix4(matrix).sub(origin).normalize().multiplyScalar(this.zLength);
    
    return new CustomPlane(origin, xAxis, yAxis, zAxis);
  }
}

function PlaneVisualizer({ plane }) {
  const origin = plane.origin;

  const xEnd = new THREE.Vector3().addVectors(origin, plane.xAxis);
  const yEnd = new THREE.Vector3().addVectors(origin, plane.yAxis);
  const zEnd = new THREE.Vector3().addVectors(origin, plane.zAxis);

  return (
    <group>
      <Line points={[origin.toArray(), xEnd.toArray()]} color="red" lineWidth={1} />
      <Line points={[origin.toArray(), yEnd.toArray()]} color="green" lineWidth={1} />
      <Line points={[origin.toArray(), zEnd.toArray()]} color="blue" lineWidth={1} />
    </group>
  );
}

export { CustomPlane, PlaneVisualizer };
